import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import { useNavigate } from 'react-router-dom';

import _, { set } from 'lodash';


import '../styles.css';

const toLocalTime = (time, tickMarkType, locale) => {
    const date = new Date(time * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    if (tickMarkType < 3) {
        return `${year}-${month}-${day}`;
    }
    return `${hour}:${minute}`;
};
const priceFormatMap = {
    "BNBUSDT": {
        minMove: 0.01,
        precision: 2,
    },
    "ETHUSDT": {
        minMove: 0.01,
        precision: 2,
    },
    "DOGEUSDT": {
        minMove: 0.00001,
        precision: 5,
    },
    "DOTUSDT": {
        minMove: 0.001,
        precision: 3,
    },
    "SOLUSDT": {
        minMove: 0.001,
        precision: 3,
    },
    "1000PEPEUSDT": {
        minMove: 0.0000001,
        precision: 7,
    },
    "ARBUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "CFXUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "LTCUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "OPUSDT": {
        minMove: 0.0000001,
        precision: 7,
    },
    "SUIUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "TRBUSDT": {
        minMove: 0.001,
        precision: 3,
    },
    "WLDUSDT": {
        minMove: 0.0001,
        precision: 4,
    },
    "default": {
        minMove: 0.001,
        precision: 3,
    }
}

const Timezone = 60 * 60 * 9;

function Live() {
    const [pointer, setPointer] = useState(0);

    // const symbols = ;
    const [symbols, setSymbols] = useState(['BTCUSDT', 'ETHUSDT', 'DOGEUSDT', 'SOLUSDT', 'XLMUSDT', '1000PEPEUSDT', 'ADAUSDT', 'SANDUSDT', 'PNUTUSDT', 'SUIUSDT', 'AVAXUSDT', 'DOTUSDT', 'SEIUSDT', 'FTMUSDT', 'TIAUSDT']);
    let symbol_dict = {};

    useEffect(() => {
        fetch('https://dashboard-api.thirteen.ai/portfolio/symbols')
            .then(response => response.json())
            .then(data => {
                // data는 API로부터 받아온 심볼 배열입니다.
                setSymbols(data);
            })
            .catch(error => console.error('Error fetching symbols:', error));
    }, []);

    useEffect(() => {
        symbol_dict = symbols.reduce((acc, symbol, index) => {
            const keys = ['q', 'w', 'e', 'r', 't', 'a', 's', 'd', 'f', 'g', 'z', 'x', 'c', 'v', 'b'];
            acc[keys[index]] = symbol;
            return acc;
        }, {});
    }, [symbols]);

    const chartContainerRef = useRef();
    const inputRef = useRef();
    const chart = useRef();
    const resizeObserver = useRef();
    // key -> try 1. get parmeter from url, 2. symbols[0]
    const params = new URLSearchParams(window.location.search);
    const [key, setKey] = useState(params.get('key') || symbols[0]);
    const [autoRefresh, setAutoRefresh] = useState(null);
    const navigate = useNavigate();
    const apiURL = "https://dashboard-api.thirteen.ai";
    // const apiURL = "http://127.0.0.1:8000";


    var candleSeries, lineSeries, dqrSeries, profitSeries, candleProfitSeries, c0profitSeries, c1profitSeries, c2profitSeries, c3profitSeries, c4profitSeries, c5profitSeries;
    var last_time_range = null;
    const initChart = () => {
        // 차트 초기화
        // if chart.current is not null, remove it
        if (chart.current) chart.current.remove();

        // create chart
        chart.current = createChart(chartContainerRef.current, {
            overlayPriceScales: {
                scaleMargins: {
                    top: 0.6,
                    bottom: 0,
                }
            },
            rightPriceScale: {
                autoScale: true,
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.08,
                }
            },
            timeScale: {
                borderColor: '#485c7b',
                timeVisible: true,
                tickMarkFormatter: toLocalTime,
            },
            localization: {
                locale: 'ko-KR',
                dateFormat: 'yy-MM-dd',
            },

        });
        candleSeries = chart.current.addCandlestickSeries({
            title: 'close',
            priceFormat: priceFormatMap[key] || priceFormatMap["default"],
        });
        lineSeries = chart.current.addLineSeries({
            title: 'entry price',
            lineWidth: 1
        });
        dqrSeries = chart.current.addHistogramSeries({
            title: 'dqr',
            priceFormat: {
                minMove: 0.001,
                precision: 3,
            },
            pane: 1
        });
        c0profitSeries = chart.current.addLineSeries({
            title: 'c0',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 2,
            color: '#e40303',
            pane: 2
        });
        c1profitSeries = chart.current.addLineSeries({
            title: 'c1',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 2,
            color: '#ff8c00',
            pane: 2
        });
        c2profitSeries = chart.current.addLineSeries({
            title: 'c2',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 2,
            color: '#ffed00',
            pane: 2
        });
        c3profitSeries = chart.current.addLineSeries({
            title: 'c3',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 2,
            color: '#008026',
            pane: 2
        });
        c4profitSeries = chart.current.addLineSeries({
            title: 'c4',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 1,
            color: '#004dff',
            pane: 2
        });
        c5profitSeries = chart.current.addLineSeries({
            title: 'c5',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 1,
            color: '#750787',
            pane: 2
        });
        profitSeries = chart.current.addLineSeries({
            title: 'live',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 1,
            color: '#2db48e',
            pane: 2
        });
        candleProfitSeries = chart.current.addLineSeries({
            title: 'close',
            priceFormat: {
                minMove: 0.00001,
                precision: 5,
            },
            lineWidth: 1,
            color: '#FF80FF',
            pane: 2
        });

        chart.current.timeScale().subscribeVisibleTimeRangeChange(_.debounce((timeRange) => {
            // 시간 범위 변경 이벤트가 1000ms간 발생하지 않을 때 호출됨
            if (last_time_range === timeRange.from) {
                console.log("Skip drawProfit", key, timeRange.from * 1000, timeRange.to * 1000, '15m');
                return;
            }
            drawProfit(key, timeRange.from * 1000 - Timezone * 1000, timeRange.to * 1000 - Timezone * 1000, '15m');
            const cumProfitCandle = cumulativeProfitCandle(timeRange.from * 1000 - Timezone * 1000, timeRange.to * 1000 - Timezone * 1000, key, '15m');
            cumProfitCandle.then((data) => {
                candleProfitSeries.setData(data);
            });

            console.log("Call drawProfit", key, timeRange.from * 1000, timeRange.to * 1000, '15m');
            last_time_range = timeRange.from;

        }, 1000));
    };

    const cumulativeProfitCandle = async (startTime, endTime, symbol, interval) => {
        const candles = await fetchHistoricalData(startTime, endTime, symbol, interval);
        // console.log(candles);
        // OHLC 데이터에서 Close Price 추출
        const closePrices = candles.map((data) => data.close);

        // 일별 수익률 계산
        const dailyReturns = closePrices.map((price, index) => {
            if (index === 0) {
                return 0;
            }
            return (price - closePrices[index - 1]) / closePrices[index - 1];
        });
        // 누적 수익 계산
        const cumulativeProfit = dailyReturns.reduce((result, dailyReturn, index) => {
            if (index === 0) {
                return [1];
            }
            const prevProfit = result[index - 1];
            result.push(prevProfit * (1 + dailyReturn));
            return result;
        }, []);

        const processedCumulativeProfit = cumulativeProfit.map((data) => (data - 1) * 100);

        const cumulativeProfitData = candles.map((data, index) => {
            return {
                time: data.time,
                value: processedCumulativeProfit[index],
            }
        });
        return cumulativeProfitData;
    }


    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            chart.current.applyOptions(
                {
                    crosshair: {
                        // Change mode from default 'magnet' to 'normal'.
                        // Allows the crosshair to move freely without snapping to datapoints
                        mode: CrosshairMode.Normal,
                
                        // Vertical crosshair line (showing Date in Label)
                        vertLine: {
                            width: 8,
                            color: '#C3BCDB44',
                            style: LineStyle.Solid,
                            labelBackgroundColor: '#9B7DFF',
                        },
                
                        // Horizontal crosshair line (showing Price in Label)
                        horzLine: {
                            color: '#9B7DFF',
                            labelBackgroundColor: '#9B7DFF',
                        },
                    },
                }
            )
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    const handleChange = (event) => {
        setKey(event.target.value);
        params.set('key', event.target.value);
        navigate(`?${params.toString()}`);
    };

    useEffect(() => {
        initChart();
        clearInterval(autoRefresh);
        loadChart(key);
        const newInterval = setInterval(() => {
            loadChart(key);
        }, 1000 * 60);

        setAutoRefresh(newInterval);
    }, [key]);

    const fetchHistoricalData = async (startTime, endTime, symbol, interval) => {
        const limit = 1000; // Maximum number of candles per request
        let chartData = [];

        while (startTime < endTime) {
            const response = await fetch(`https://fapi.binance.com/fapi/v1/klines?symbol=${symbol}&interval=${interval}&startTime=${startTime}&endTime=${endTime}&limit=${limit}`);
            const data = await response.json();
            if (data.length === 0) break; // No more data available
            chartData = [...chartData, ...data.map(item => ({
                time: item[0] / 1000,
                open: parseFloat(item[1]),
                high: parseFloat(item[2]),
                low: parseFloat(item[3]),
                close: parseFloat(item[4]),
            }))];
            startTime = data[data.length - 1][0] + 1; // Set start time to end time of last candle + 1
        }

        // fix timezone
        chartData = chartData.map((data) => {
            return {
                time: data.time + Timezone,
                open: data.open,
                high: data.high,
                low: data.low,
                close: data.close,
            }
        });
        return chartData;
    };


    const fetchDQR = async (symbol, st, ed) => {
        const response = await fetch(`${apiURL}/dqr?symbol=${symbol}&start_time=${st}&end_time=${ed}&v=`);
        const data = await response.json();
        const dqrData = data.map(item => ({
            time: item.timestamp / 1000 + Timezone,
            value: parseFloat(item.dqr),
            color: item.dqr < 0 ? '#E83B3F' : '#239888',
        }));
        dqrSeries.setData(dqrData);
    };

    const fetchProfit = async (session_id, st, ed, interval, db_name) => {
        const response = await fetch(`${apiURL}/portfolio/list?session_ids=${session_id}&db_name=${db_name}&start_time=${st}&end_time=${ed}&interval=${interval}`);
        const data = await response.json();
        const profitData = data.map(item => ({
            time: item.timestamp / 1000 + Timezone,
            value: parseFloat(item.cumprod),
        }));
        return profitData;
    };

    const drawProfit = async (symbol, st, ed, interval) => {
        const session_id = `L_${symbol.substring(0, symbol.length - 4)}`;
        const profitData = await fetchProfit(session_id, st, ed, interval, 'live');
        profitSeries.setData(profitData);
        // const c0_session_id = `P_${symbol.substring(0, symbol.length - 4)}-C0`;
        // const c0_profitData = await fetchProfit(c0_session_id, st, ed, interval, 'paper');
        // c0profitSeries.setData(c0_profitData);

        // const c2_session_id = `P_${symbol.substring(0, symbol.length - 4)}-C5`;
        // const c2_profitData = await fetchProfit(c2_session_id, st, ed, interval, 'paper');
        // c2profitSeries.setData(c2_profitData);

        // const c3_session_id = `P_${symbol.substring(0, symbol.length - 4)}-C6`;
        // const c3_profitData = await fetchProfit(c3_session_id, st, ed, interval, 'paper');
        // c3profitSeries.setData(c3_profitData);

        // const c4_session_id = `P_${symbol.substring(0, symbol.length - 4)}-C7`;
        // const c4_profitData = await fetchProfit(c4_session_id, st, ed, interval, 'paper');
        // c4profitSeries.setData(c4_profitData);
    };

    const loadChart = (symbol) => {
        var start_date, end_date, interval;
        interval = "15m";

        // Fetch initial chart data
        const fetchInitialData = async () => {
            end_date = Date.now() + 60 * 60 * 1000;  // 1 hour later
            start_date = Date.now() - 60 * 60 * 24 * 30 * 1000;  // 30 days ago
            const chartData = await fetchHistoricalData(start_date, end_date, symbol, interval);

            start_date = chartData[0].time * 1000 - 1;
            end_date = chartData[chartData.length - 1].time * 1000 + 1;
            candleSeries.setData(chartData);
            // fetchProfit(symbol, start_date, end_date, interval);
            fetchDQR(symbol, start_date, end_date);
            // fetchPostion(symbol, start_date, end_date, interval);
        };

        fetchInitialData();
        // Set up WebSocket to receive real-time updates
        // const socket = new WebSocket(`wss://fstream.binance.com:9443/stream?streams=${symbol.toLowerCase()}@kline_${interval}`);
        const socket = new WebSocket(`wss://fstream.binance.com/stream?streams=${symbol.toLowerCase()}@kline_${interval}`);
        socket.onmessage = event => {
            const data = JSON.parse(event.data);
            const candle = data.data.k;
            const candleData = {
                time: (candle.t / 1000) + 9 * 60 * 60,
                open: parseFloat(candle.o),
                high: parseFloat(candle.h),
                low: parseFloat(candle.l),
                close: parseFloat(candle.c),
            };
            candleSeries.update(candleData);
        };
    }
    function handleKeyPress(event) {
        const key = event.key;
        if (symbol_dict.hasOwnProperty(key)) {
            setKey(symbol_dict[key]);
        }
        event.stopPropagation();
    }

    useEffect(() => {
        window.addEventListener('keyup', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div>
            <label style={{ marginRight: 10 }}>
                Current Symbol : {key}
            </label>
            <label>
                Session ID:
                <select value={key} onChange={handleChange} ref={inputRef}>
                    {symbols.map((symbol) => {
                        return (
                            <option key={symbol} value={symbol}>{symbol}</option>
                        )
                    })}
                </select>
            </label>
            <div className="LiveApp">
                <div ref={chartContainerRef} className="live-chart-container" />
            </div>
        </div>
    );


}

export default Live;
